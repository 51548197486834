import React from "react";
import { useMsal } from "@azure/msal-react";

/**
 * Renders a sign out button 
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect({
        postLogoutRedirectUri: "/",
    });
  };

  return (
    <button 
      onClick={() => handleLogout()} 
      className="bg-blue-500 text-white px-4 py-2 rounded"
    >
        Sign Out
    </button>
  );
};