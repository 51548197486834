import React from "react";
import { useMsal } from "@azure/msal-react";

import { loginRequest } from "./config/authConfig";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 * Note the [useMsal] package 
 */

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch((e) => {
            // console.log(e);
        });
    };
  return (
    <button 
      onClick={() => handleLogin()} 
      className="bg-blue-500 text-white px-4 py-2 rounded"
      >
        Sign In
    </button>
  );
};