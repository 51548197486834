"use client";

import React from 'react'
import ReactDOM from 'react-dom/client'
import { ErrorBoundary } from "react-error-boundary";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { msalConfig } from './config/authConfig';

import './index.css'
import App from './App'

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ErrorBoundary fallback={<div>Something went wrong. Please try again or contact IT for help.</div>}>
        <App />
      </ErrorBoundary>
    </MsalProvider>
  </React.StrictMode>
)