import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

// pages
import Home from "./pages/Home";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";


function App() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <BrowserRouter>
      <>
        <nav className="sticky top-0 h-20 bg-gray-800 p-5 sm:px-24 xl:px-64 z-50">
          <div className="mx-auto flex justify-between items-center">
            <Link to="/" className="uppercase text-white text-xl xl:text-3xl font-bold whitespace-no-wrap font-Montserrat leading-normal tracking-tighter">Products</Link>
            <button className="block md:hidden uppercase inline-flex items-center bg-green-500 px-3 py-2 rounded-lg text-white">
                Menu 
                <svg className="h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
                </svg>
            </button>
            <div className="hidden md:block"></div>
            {isAuthenticated ? <SignOutButton /> : <SignInButton />}
          </div>
        </nav>
      </>
      {isAuthenticated &&
        <Routes>
            <Route path="/" element={<Home />} />
        </Routes>  
      }
    </BrowserRouter>
  );
}

export default App;