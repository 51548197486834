function StockItemDetails(props) {
    return (
        <>
            {props.stockItem.itemClass && (<span className="text-gray-400 mr-3 p-5 pt-10 uppercase text-xs truncate ...">{props.stockItem.itemClass}</span>)}
            <div className="p-4">
                <h2 className="mt-2 mb-2  font-bold">{props.stockItem.name}</h2>
                
                <div className="accordion" id="accordionExample">
                    <div className="bg-white w-full rounded-lg border border-gray-200 divide-y divide-gray-200">
                        <details>
                            <summary className="question py-3 px-4 cursor-pointer select-none w-full outline-none">Description</summary>
                            
                            {props.stockItem.description && (<p className="pt-1 pb-3 px-4">{props.stockItem.description}</p>)}
                        </details>
            
                    </div>
                </div>
                <div className="mt-3">
                    <p className="text-sm">{'Quantity available for purchase: ' + (props.getQuantityAvailable(props.stockItem))}</p>

                    {(!props.queryShowroom && props.stockItem.promisedQty) ? (<p className="text-sm mt-1">{'Promised quantity: ' + props.stockItem.promisedQty}</p>) : ''}

                    {(!props.queryShowroom && props.stockItem.promisedDate) ? (<p className="text-sm mt-1">{'Promised date: ' + new Date(props.stockItem.promisedDate).toLocaleDateString('en-US')}</p>) : ''}
                    
                    {(props.stockItem.reasonForDonation) ? (<p className="text-sm">{'Reason for donation: ' + (props.stockItem.reasonForDonation)}</p>) : ''}
                </div>
                {(props.stockItem.price !== null && props.stockItem.price !== undefined) && 
                    <div className="mt-3 flex items-center">
                        <span className="text-xl mr-1">MSRP</span><span className="text-xl font-semibold">$</span><span className="font-bold text-xl">{(props.stockItem.price).toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
                    </div>}
            </div>
            <div className="p-4 border-t border-b  text-gray-700">
                <span className="flex items-center">
                    {props.stockItem.sku && (<p className="ftext-gray-200 text-lg">{props.stockItem.sku}</p>)} 
                </span>
            </div>
        </>
    );
}

export default StockItemDetails;