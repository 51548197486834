import { useEffect, useState, useRef, useMemo } from "react";

import LocalStorageService from "../LocalStorageService";
import StockItemDetails from "../StockItemDetails";
import { debounce } from "../utils";

const Home = () => {
  const [inputSku, setInputSku] = useState('');
  const [querySku, setQuerySku] = useState('');
  const [queryShowroom, setQueryShowroom] = useState('');
  const [inputPage, setInputPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [stockItemsRequestState, setStockItemsRequestState] = useState({
    data: [],
    loading: true,
    failed: false,
    status: null,
    message: null
  });
  const [showroomsRequestState, setShowroomsRequestState] = useState({
    data: [],
    loading: true,
    failed: false,
    status: null,
    message: null
  });
  const [stockItems, setStockItems] = useState([]);
  const [showrooms, setShowrooms] = useState([]);
  const [isFlashcardMode, setIsFlashcardMode] = useState(false);
  const localStorageService = useRef(new LocalStorageService());

  const debouncedPageChangeHandler = useMemo(
    () =>  debounce((input) => setCurrentPage(input), 500)
  , []);

  useEffect(() => {
    const localShowrooms = localStorageService.current.getShowrooms();

    if (localShowrooms && localShowrooms.length > 0) {
      setShowrooms(localShowrooms);
      return;
    }

    const url = `${process.env.REACT_APP_API_BASE_URI}/showrooms`;

    const fetchShowrooms = async () => {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      fetch(url, requestOptions)
      .then(
        response => {
          if (response.ok) {
            return response.json();
          } else {
            throw response;
          }
        }
      ).then(data => {
        localStorageService.current.setShowrooms(data);
        setShowrooms(data);
        setShowroomsRequestState((prevState) => ({
        ...prevState,
        data: data,
      }))})
      .catch(
        error => {
          setShowroomsRequestState((prevState) => ({
            ...prevState,
            failed: true,
            status: error.status,
          }));

          error.text().then(errorBody => {
            setShowroomsRequestState((prevState) => ({
              ...prevState,
              message: (typeof errorBody === 'string' || errorBody instanceof String) ? errorBody : null,
            }))
          });
        }
      )
      .finally(() => {
        setShowroomsRequestState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
  };

  fetchShowrooms();
  }, []);

  useEffect(() => {
    setStockItemsRequestState((prevState) => ({
      ...prevState,
      message: null,
      failed: false,
      loading: true,
      status: null,
      data: []
    }));

    setStockItems([]);

    const localItems = localStorageService.current.getStockItems();
    const localCount = localStorageService.current.getTotalPages();
    const isInInitialState = !querySku && !queryShowroom && currentPage === 1;

    if (localItems && localItems.length > 0 && isInInitialState && localCount) {
      setStockItems(localItems);
      setTotalPages(localCount);

      return;
    }

    const url = `${process.env.REACT_APP_API_BASE_URI}/stock-items${createQuery()}`;

    function createQuery() {
      if (isInInitialState) {
        return '';
      }

      let queryString = '?';

      if (querySku) {
        queryString += `sku=${querySku}&`;
      }

      if (queryShowroom) {
        queryString += `showroom=${queryShowroom}&`;
      }

      queryString += `page=${currentPage}&`;

      return queryString;
    }

    const fetchStockItems = async () => {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      fetch(url, requestOptions)
        .then(
          response => {
            if (response.ok) {
              return response.json();
            } else {
              throw response;
            }
          }
        ).then(data => {
          const items = data.stockItems;
          const totalPages = data.totalPages;

          if (isInInitialState) {
            localStorageService.current.setStockItems(items);
            localStorageService.current.setTotalPages(totalPages);
          }
          
          setStockItems(items);
          setTotalPages(totalPages);
          setStockItemsRequestState((prevState) => ({
          ...prevState,
          data: data,
        }))})
        .catch(
          error => {
            setStockItemsRequestState((prevState) => ({
              ...prevState,
              failed: true,
              status: error.status,
            }));

            try {
              error.text().then(errorBody => {
                setStockItemsRequestState((prevState) => ({
                  ...prevState,
                  message: (typeof errorBody === 'string' || errorBody instanceof String) ? errorBody : null,
                }))
              });
            } catch(error){

            }
          }
        )
        .finally(() => {
          setStockItemsRequestState((prevState) => ({
            ...prevState,
            loading: false,
          }));
        });
    };

    fetchStockItems();
    }, [querySku, queryShowroom, currentPage]);

  const handleButtonClick = () => {
    if (inputSku === querySku) {
      return;
    }

    setInputPage(1);
    setCurrentPage(1);
    setQuerySku(inputSku);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Prevent the default behavior (e.g., form submission)
      event.preventDefault();

      if (inputSku === querySku) {
        return;
      }

      setInputPage(1);
      setCurrentPage(1);
      setQuerySku(inputSku);
    }
  };

  const handleSelectChange = (event) => {
    const newShowroom = event.target.value;

    if (newShowroom !== queryShowroom) {
      setInputPage(1);
      setCurrentPage(1);
    }
    setQueryShowroom(newShowroom);
  };

  const handleToggle = () => {
    setIsFlashcardMode(!isFlashcardMode);
  };

  function getQuantityAvailable(stockItem) {
    if (queryShowroom && stockItem.showroomAvailability) {
      return stockItem.showroomAvailability.find(showroom => showroom.showroomName === queryShowroom) ? stockItem.showroomAvailability.find(showroom => showroom.showroomName === queryShowroom).qtyAvailable : '';
    }

    return stockItem.qtyAvailable;
  }

  function getErrorMessage() {
    if (stockItemsRequestState.status === 404) {
      return stockItemsRequestState.message;
    }

    return 'There was an issue loading the data.';
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setInputPage(newPage);
      setCurrentPage(newPage);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setInputPage(newPage);
      setCurrentPage(newPage);
    }
  };

  const handlePageInputChange = (event) => {
    const newPage = parseInt(event.target.value, 10);

    if (!isNaN(newPage) && newPage > 0 && newPage <= totalPages) {
      setInputPage(newPage);
      debouncedPageChangeHandler(newPage);
    }
  }
    
    return (
      <>
        <div className="flex justify-around items-center bg-blue-100 py-2">
          <div className="flex items-center">
            <label className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  id="toggle"
                  className="sr-only"
                  checked={isFlashcardMode}
                  onChange={handleToggle}
                />
                <div className="toggle__line w-10 h-4 bg-gray-800 rounded-full shadow-inner"></div>
                <div
                  className={`toggle__dot absolute w-6 h-6 rounded-full shadow -left-1 -top-1 transition transform ${isFlashcardMode ? 
                    'translate-x-full bg-blue-500' : 'bg-gray-800'}`}>
                </div>
              </div>
              <div className="ml-3 text-gray-900 font-medium text-lg">Flashcard Mode</div>
            </label>
          </div>
          <div className="flex justify-center items-center  rounded-lg bg-white relative">
            <div className="search-icon bg-blue-500 hover:bg-blue-400 text-white px-4 py-4 rounded-lg relative z-10 shadow-md" onClick={handleButtonClick}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 19L13 13M15 8C15 8.91925 14.8189 9.82951 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.82951 14.8189 8.91925 15 8 15C7.08075 15 6.1705 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.82951 1 8.91925 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <input className="search-input rounded-r-lg text-lg ml-2 text-gray-900 font-medium outline-none focus:outline-none:focus" type="search" autoComplete="off" spellCheck="false" aria-live="polite" placeholder="Enter SKU" onChange={(e)=>setInputSku(e.target.value)} onKeyDown={handleInputKeyPress}/>
          </div>
          <div className="text-left">
            <select
              value={queryShowroom}
              onChange={handleSelectChange}
              className="rounded-r-lg text-lg ml-2 text-gray-900 font-medium w-full px-3 py-3 pr-10 rounded leading-tight outline-none focus:outline-none:focus"
            >
              <option value="">Warehouse storage</option>
              {showrooms && (<>{showrooms.map((showroom) => <option key={showroom} value={showroom}>{showroom}</option>)}</>)}
            </select>
          </div>
        </div>
        <div className="antialiased bg-gray-200 text-gray-900 font-sans p-6">
          <div className="container mx-auto">
            <div className="flex flex-wrap -mx-4 justify-center">
              {stockItemsRequestState.failed && (<p className="ml-3 text-gray-900 text-lg font-medium">{getErrorMessage()}</p>)}
              {stockItems && ( 
                <>
                  {stockItems.map((stockItem, index) => 
                    (
                      <div key={stockItem.sku || index} className="w-full sm:w-1/2 md:w-1/2 xl:w-1/4 p-4">
                        <div href="" className="c-card block bg-white shadow-md hover:shadow-xl rounded-lg overflow-hidden">
                          <div className="relative pb-1 overflow-hidden flex justify-center">
                            <img className="rounded-t-lg h-68 max-h-68" src={stockItem.imageUrl ? stockItem.imageUrl : 'filler.jpg'} alt={stockItem.name}/>
                          </div>
                          {isFlashcardMode ? 
                            (
                              <details>
                                <summary className="question py-3 px-4 cursor-pointer select-none w-full outline-none mb-5">Details</summary>
                                <StockItemDetails stockItem={stockItem} queryShowroom={queryShowroom} getQuantityAvailable={getQuantityAvailable} />
                              </details>
                            ) : (
                              <StockItemDetails stockItem={stockItem} queryShowroom={queryShowroom} getQuantityAvailable={getQuantityAvailable} />
                            )}
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
          {(!stockItemsRequestState.failed || stockItemsRequestState.message === 'Page not found') && 
            (
              <div className="flex items-center justify-center space-x-4 mt-4">
                <button
                  onClick={handlePrevPage}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span className="text-gray-700">
                  Page       
                  <input
                    type="number"
                    value={inputPage}
                    onChange={handlePageInputChange}
                    className="border border-gray-300 px-2 py-1 rounded text-center"
                    style={{ maxWidth: '4rem' }}
                  /> 
                  of {totalPages}
                </span>
                <button
                  onClick={handleNextPage}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div> 
            )
          }
        </div>
      </>
    )
}

export default Home;