class LocalStorageService {
    #keys = {
        stockItems: 'stockItems',
        showrooms: 'showrooms',
        totalPages: 'totalPages'
    };
  
    constructor() {
        this.storage = window.localStorage;
    }
  
    getStockItems() {
        const stockItemsObject = JSON.parse(this.storage.getItem(this.#keys.stockItems)) || null;

        if (!stockItemsObject) {
            return [];
        }

        if (!this.areSameDate(new Date(stockItemsObject.dateSaved), new Date())) {
            return [];
        }

        return stockItemsObject.stockItems;
    }
  
    setStockItems(stockItems) {
        const stockItemsObject = {
            stockItems: stockItems,
            dateSaved: new Date().toISOString()
        };

        this.storage.setItem(this.#keys.stockItems, JSON.stringify(stockItemsObject));
    }

    getShowrooms() {
        const showroomsObject = JSON.parse(this.storage.getItem(this.#keys.showrooms)) || null;

        if (!showroomsObject) {
            return [];
        }

        if (!this.areSameDate(new Date(showroomsObject.dateSaved), new Date())) {
            return [];
        }

        return showroomsObject.showrooms;
    }

    setShowrooms(showrooms) {
        const showroomsObject = {
            showrooms: showrooms,
            dateSaved: new Date().toISOString()
        }

        this.storage.setItem(this.#keys.showrooms, JSON.stringify(showroomsObject));
    }

    getTotalPages() {
        const totalPagesObject = JSON.parse(this.storage.getItem(this.#keys.totalPages)) || null;

        if (!totalPagesObject) {
            return [];
        }

        if (!this.areSameDate(new Date(totalPagesObject.dateSaved), new Date())) {
            return [];
        }

        return totalPagesObject.totalPages;
    }

    setTotalPages(totalPages) {
        const totalPagesObject = {
            totalPages: totalPages,
            dateSaved: new Date().toISOString()
        }

        this.storage.setItem(this.#keys.totalPages, JSON.stringify(totalPagesObject));
    }

    areSameDate(d1, d2) {
        return d1.getFullYear() === d2.getFullYear()
            && d1.getMonth() === d2.getMonth()
            && d1.getDate() === d2.getDate();
    }
}  

export default LocalStorageService;